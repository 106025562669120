import { IpLightListData } from "../interfaces/IPLightList";
import { WifiData } from "../interfaces/CertWifi";
import { ResetPasswordToken } from "../interfaces/ResetPasswordToken";
import { LoginUser } from "../interfaces/LoginUser";
import { UserInfoData } from "../interfaces/UserInfo";
import { PingResponse } from "../interfaces/PingResponse";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import ApiError from "../utils/ApiError";
import keycloak from "../config/keycloak";
import { Certificado } from "../interfaces/BandaLarga";

const BASE_URL: string = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : 'http://127.0.0.1:8000/api/v1/web'; // Replace with your API base URL

// Helper function to make HTTP requests
async function request(url: string, method: string = 'GET', data: any = null, headers: any = {}): Promise<any> {
  const access_token = keycloak.token;
  const authHeaders = access_token ? { Authorization: `Bearer ${access_token}` } : {};

  const config: AxiosRequestConfig = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      ...authHeaders,
    },
    url: `${BASE_URL}${url}`,
    data: data ? JSON.stringify(data) : undefined,
  };

  try {
    const response = await axios(config);

    return response.data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      throw new ApiError({ code: error.code, message: error.message, cause: error.cause, responseData: error.response?.data });
    }
    throw new Error(`API Error: ${error.message}`);
  }
}


export async function loginRequest(credentials: { username: string; password: string; }): Promise<LoginUser> {
  return await request('/user/login', 'POST', credentials);
}

export async function registerTokenRequest(username: string): Promise<ResetPasswordToken> {
  return await request(`/user/${username}/new-token`, 'POST');
}

export async function refreshTokenRequest(header: { Authorization: string; }): Promise<any> {
  return await request(`/user/refresh`, 'POST', null, header);
}

export async function resetPasswdRequest(username: string, body: { token: string; password: string; }): Promise<any> {
  return await request(`/user/${username}/reset-passwd`, 'POST', body);
}

export async function validateRecaptchaRequest(recaptcha: string): Promise<any> {
  return await request(`/validate-recaptcha`, 'POST', recaptcha);
}

export async function registerRequest(cadastro: { name: string; username: string; empresa: string; password: string; email: string; perfil: string; }): Promise<any> {
  return await request('/user/new-user', 'POST', cadastro);
}

// export async function ListRequest(): Promise<IpLightListData[]> {
//   return await request('/cert/internet', 'GET');
// }

export async function WifiListRequest(filter: string, value: string): Promise<IpLightListData[]> {
  return await request(`/cert/wifi?search=${value}`, 'GET');
}

export async function InternetListRequest(filter: string, value: string): Promise<IpLightListData[]> {
  return await request(`/cert/internet?search=${value}`, 'GET');
}

export async function bandaLargaCert(id: string): Promise<Certificado> {
  return await request(`/cert/internet/${id}`, 'GET');
}

export async function WifiCert(id: string): Promise<WifiData> {
  return await request(`/cert/wifi/${id}`, 'GET');
}


export async function pendingUsersRequest(): Promise<UserInfoData[]> {
  return await request(`/users/search-users`, 'GET');
}

export async function approveUsersRequest(body: { id: string, role: string; segmento: string; }[]): Promise<UserInfoData[]> {
  return await request(`/users/aprov-users`, 'POST', body);
}

export async function pingRequest(body: { host: string; }): Promise<PingResponse> {
  return await request(`/ping`, 'POST', body);
}
