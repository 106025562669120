import { ComponentProps } from "react"

import styles from "./Footer.module.css";

interface FooterProps extends ComponentProps<"footer">{

}

const Footer = (props: FooterProps) => {
  return (
    <>
    <hr style={{ margin: "auto 0 0", width: "99%" }} />
    <footer className={styles.footer} {...props} >
      <span>&copy;</span><p> MagicTool - Centro De Desenvolvimento De Dispositivos </p>
    </footer>
    </>
  );
};

export default Footer;