import React, { useState, useEffect } from 'react'

import styles from "./Internet.module.css"
import { useParams } from 'react-router-dom';
import { Certificado, DadosAtividade } from '../../interfaces/BandaLarga';
import { bandaLargaCert } from '../../services/api';

import "react-circular-progressbar/dist/styles.css";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DnsIcon from '@mui/icons-material/Dns';

import iconInternet from "../../assets/images/internet.png"
import router from "../../assets/images/router.png"
import telephone from "../../assets/images/telephone.png"
import wifi from "../../assets/images/wifi.png"
import display from "../../assets/images/display.png"
import hgu from "../../assets/cpes/1_geral.png"

import DonnutChart from '../../components/GraphicsChart/DonnutChart';
import Carrousel from '../../components/Carrousel/Carrousel';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';


type statusType = {
    status: string,
    desc: string
}

type CardsType = {
    title: string,
    statuses: statusType[]
}

type Props = {
    Info: DadosAtividade | undefined,
    Image: any
}

export const Aside = ({ Info, Image }: Props) => {
    return (
        <Card variant="outlined" sx={{ borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", height: "98%" }}>
            <CardContent className={styles.asideContent}>
                {Info && <>
                    <Typography className={styles.dateExec} component="p">{Info.sent_time}</Typography>
                    <Typography className={styles.tecLogin} component="div">
                        <span>
                            <p className={styles.h1Atividade}>Ordem</p>
                            <p className={styles.labelAtividade}>{Info.ordem_id_eta} </p>
                        </span>
                        <hr />
                        <span>
                            <p className={styles.h1Atividade}>Login Técnico</p>
                            <p className={styles.labelAtividade}>{Info.login_tecnico}</p>
                        </span>
                    </Typography>
                    <hr />
                    <Typography className={styles.hguImage} component='div'>
                        <img src={Image} alt='HGU Imagem' style={{ width: "44%" }} />
                        <p className={styles.labelAtividade}>{Info.modelo}</p>
                    </Typography>
                    <hr style={{ margin: "0" }} />
                    <Typography className={styles.appVersion}>
                        <div>
                            <p className={styles.h1Atividade}>Firmware:</p>
                            <p className={styles.labelAtividade}>{Info.firmware}</p>
                        </div>
                        {/* <hr /> */}

                    </Typography>
                    <hr />
                    <Typography className={styles.appVersion}>
                        {/* {Info.modelo && <>
                            <Typography className={styles.infoAtividade} >
                                <p className={styles.h1Atividade}>Modelo:</p>
                                <p className={styles.labelAtividade}>{Info.modelo}</p>
                            </Typography>
                            </>} */}
                        {/* <hr /> */}
                        <div>
                            <p className={styles.h1Atividade}>Designador Internet:</p>
                            <p className={styles.labelAtividade}>{Info.designador}</p>
                        </div>
                    </Typography>
                    <hr />
                    <Typography className={styles.appVersion}>
                        <div>
                            <p className={styles.h1Atividade}>Serial:</p>
                            <p className={styles.labelAtividade}>{Info.serial_number}</p>
                        </div>
                        <hr />
                        <div>
                            <p className={styles.h1Atividade}>Instancia:</p>
                            <p className={styles.labelAtividade}>{Info.segmento}</p>
                        </div>
                    </Typography>
                    <hr />
                    <Typography className={styles.appVersion}>
                        {/* <div>
                                <p className={styles.h1Atividade}>Instancia:</p> 
                                <p className={styles.labelAtividade}>{Info.segmento}</p>
                            </div>
                        */}
                        <div>
                            <p className={styles.h1Atividade}>Designador TV:</p>
                            <p className={styles.labelAtividade}>empty</p>
                        </div>
                        <hr />
                        <div>
                            <p className={styles.h1Atividade}>Suporte WIFI 6:</p>
                            <p className={styles.labelAtividade}>SIM</p>
                        </div>
                    </Typography>
                    <hr />

                    <Typography className={styles.appVersion}>
                        <div>
                            <p className={styles.h1Atividade}>Velocidade:</p>
                            <p className={styles.labelAtividade}>{Info.veloc_contratada}</p>
                        </div>
                        <hr />
                        <div>
                            <p className={styles.h1Atividade}>Versão do Aplicativo</p>
                            <span className={styles.spanVersion}><p className={styles.labelAtividade}>{Info.app_version}</p></span>
                        </div>
                    </Typography>
                </>}
            </CardContent>
        </Card>
    )
}

const Internet = () => {

    const { value } = useParams();
    const [data, setData] = useState<Certificado | undefined>()
    const [cards, setCards] = useState<CardsType[] | undefined>()
    const [loading, setLoading] = useState<boolean>(false)

    const setCertificado = async (id: string) => {
        setLoading(true)
        const res = await bandaLargaCert(id)
        setData(res)
        setCards([
            {
                title: "Internet",
                statuses: [
                    {
                        status: res.cert_wan_inet.status_iface_validation,
                        desc: "Interface Internet"
                    },
                    {
                        status: res.cert_wan_inet.status_vlan_validation,
                        desc: "Vlan"
                    },
                    {
                        status: res.cert_wan_dns.dns_ipv4_validation,
                        desc: "IPv4"
                    },
                    {
                        status: res.cert_wan_inet.status_rotas_validation,
                        desc: "Rotas"
                    },
                    {
                        status: res.cert_wan_dns.status_cert_inet,
                        desc: "DNS"
                    },
                ]
            },
            {
                title: "Rede 5GHz",
                statuses: [
                    {
                        status: res.cert_wifi_5G.status_validation,
                        desc: "Status Interface"
                    },
                    {
                        status: res.cert_wifi_5G.bandwidth_validation,
                        desc: "Largura do Canal"
                    },
                    {
                        status: res.cert_wifi_5G.auth_validation,
                        desc: "Autenticação"
                    },
                    {
                        status: res.cert_wifi_5G.channel_mode_validation,
                        desc: "Smart Channel Select"
                    },
                ]
            },
            {
                title: "Rede 5GHz BS",
                statuses: [
                    {
                        status: res.cert_wifi_5G_BS.status_validation,
                        desc: "Status Interface"
                    },
                    {
                        status: res.cert_wifi_5G_BS.bandwidth_validation,
                        desc: "Largura do Canal"
                    },
                    {
                        status: res.cert_wifi_5G_BS.auth_validation,
                        desc: "Autenticação"
                    },
                    {
                        status: res.cert_wifi_5G.channel_mode_validation,
                        desc: "Smart Channel Select"
                    },
                ]
            },
            {
                title: "Rede 2.4GHz",
                statuses: [
                    {
                        status: res.cert_wifi_2G.status_validation,
                        desc: "Status Interface"
                    },
                    {
                        status: res.cert_wifi_2G.bandwidth_validation,
                        desc: "Largura do Canal"
                    },
                    {
                        status: res.cert_wifi_2G.auth_validation,
                        desc: "Autenticação"
                    },
                    {
                        status: res.cert_wifi_2G.channel_mode_validation,
                        desc: "Troca de Canal Inteligente"
                    },
                ]
            },
            {
                title: "Smart Home",
                statuses: [
                    {
                        status: res.cert_wifi_smartHome.roaming_validation,
                        desc: "Roaming"
                    },
                    {
                        status: res.cert_wifi_smartHome.domain_5G_bp_validation,
                        desc: "BP 5GHz"
                    },
                    {
                        status: res.cert_wifi_smartHome.qhop_validation,
                        desc: "QHOP"
                    },
                    {
                        status: res.cert_wifi_smartHome.airfair_validation,
                        desc: "Airfair"
                    },
                    {
                        status: res.cert_wifi_smartHome.beamforming_validation,
                        desc: "Beamforming"
                    },
                ]
            },
            {
                title: "Rede Local",
                statuses: [
                    {
                        status: res.cert_lan_iface.ip_gateway_validation,
                        desc: "IP Internet"
                    },
                    {
                        status: res.cert_lan_iface.ip_dns_validation,
                        desc: "Testes DNS"
                    },
                ]
            },
            {
                title: "VoIP",
                statuses: [
                    {
                        status: res.cert_voip.status_interface_validation,
                        desc: "Status Interface"
                    },
                    {
                        status: res.cert_voip.status_cert_voip,
                        desc: "Configuração parametros"
                    },
                    {
                        status: res.cert_voip.status_register_validation,
                        desc: "Status Registro"
                    },
                ]
            },
        ])
        setLoading(false)
    }

    useEffect(() => {
        if (value) {
            setCertificado(value)
        }
    }, [value])

    let total: number = 0

    let succes_percent: number = 0
    let warnning_percent: number = 0
    let failed_percent: number = 0


    const porcentagem = (valor_base: number, percent: number): number => {
        const resultado = (percent / valor_base) * 100

        return resultado
    }

    if (data) {
        total = (data.dadosAtividade.warning + data.dadosAtividade.failed + data.dadosAtividade.passed)
        succes_percent = porcentagem(total, data.dadosAtividade.passed)
        warnning_percent = porcentagem(total, data.dadosAtividade.warning)
        failed_percent = porcentagem(total, data.dadosAtividade.failed)
    }

    return (
        <div className={styles.main}>
            {
                loading &&
                <Box sx={{
                    display: 'flex',
                    margin: "5em",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    height: "100vh",
                    marginTop: "0px",
                }}>
                    <CircularProgress sx={{
                        margin: "auto"
                    }} />
                </Box>
            }
            {
                !loading &&
                <>
                    <section className={styles.container}>
                        <article className={styles.graphicGroup}>
                            <div>
                                <Card variant="outlined" sx={{ borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", height: 300 }}>
                                    {data?.resultado_geral === "OK" ?
                                        <Typography variant='h5' sx={{ padding: "5px 0 0 22px", display: "flex", gap: "1rem", fontWeight: "600" }} >Status da Certificação: <p className={styles.resultadoCertOK}>Aprovada</p></Typography>
                                        : <Typography variant='h5' sx={{ padding: "5px 0 0 22px", display: "flex", gap: "1rem", fontWeight: "600" }} >Status da Certificação: <p className={styles.resultadoCertNOK}>Reprovada</p></Typography>}
                                    <CardContent sx={{ textAlign: "center", marginTop: "-5px" }} className={styles.mygrap}>

                                        <DonnutChart name='Aprovados' percent={succes_percent} test={data?.dadosAtividade.passed} />
                                        <DonnutChart name='Alarmes' percent={warnning_percent} test={data?.dadosAtividade.warning} />
                                        <DonnutChart name='Reprovados' percent={failed_percent} test={data?.dadosAtividade.failed} />

                                    </CardContent>
                                </Card>
                            </div>
                            <div className={styles.topoServices}>
                                <Card variant="outlined" sx={{ width: "63%", borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", height: 200 }}>
                                    <Typography variant='h5' sx={{ textAlign: "center", padding: "10px 0 0 0", fontWeight: "bold" }}>Topologia</Typography>
                                    <CardContent className={styles.topolyContent}>
                                        <div style={{ margin: "15px 0 0 0 ", textAlign: "center" }}>
                                            <img src={iconInternet} alt='NetworkIcon' />
                                            <p style={{ margin: 0, fontWeight: 500 }}>Internet</p>
                                        </div>

                                        <div className={styles.topologyDiv}>
                                            {data?.cert_wan_omci.status_cert_gpon !== "passed" ? <p className={styles.auth}>Autenticado</p> :
                                                <p className={styles.no_auth}>Não Autenticado</p>}
                                            <hr style={{ width: "7rem" }} />
                                        </div>
                                        <div style={{ margin: "15px 0 0 0 ", textAlign: "center" }}>
                                            <DnsIcon sx={{ width: "55px", height: "100%" }} />
                                            <p style={{ margin: 0, fontWeight: 500 }}>OLT</p>
                                        </div>

                                        {/* <img src={DnsIcon} alt='NetworkIcon' style={{width: "10%", height: "25%"}} /> */}
                                        <div className={styles.topologyDiv}>
                                            <p className={styles.bdm}>{data?.cert_wan_omci.hgu_rx}</p>
                                            <hr style={{ width: "7rem" }} />
                                        </div>
                                        <div style={{ margin: "15px 0 0 0 ", textAlign: "center" }}>
                                            <img src={router} alt='NetworkIcon' />
                                            <p style={{ margin: 0, fontWeight: 500 }}>HGU</p>
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card variant="outlined" sx={{ width: "35%", borderRadius: "10px", minWidth: 275, boxShadow: "-9px 10px 15px #a9a9a92b", height: 200 }}>
                                    <CardContent>
                                        <Typography variant='h5' sx={{ textAlign: "center", fontWeight: "bold" }}>Serviços</Typography>
                                        <div className={styles.divService} >
                                            <div style={{ textAlign: "center" }}>
                                                <span>
                                                    <img src={telephone} alt='telefone' />
                                                    <p style={{ margin: 0 }} className={styles.connectedServices}>0</p>
                                                </span>
                                                <p style={{ margin: 0, fontWeight: 500 }}>VoIP</p>
                                            </div>
                                            <div style={{ textAlign: "center" }}>
                                                <span>
                                                    <img src={display} alt='Computador' />
                                                    <p style={{ margin: 0 }} className={styles.connectedServices}>0</p>
                                                </span>
                                                <p style={{ margin: "0 23px 0 0", fontWeight: 500 }}>TV</p>
                                            </div>
                                            <div style={{ textAlign: "center" }}>
                                                <span>
                                                    <img src={wifi} alt='ROUTER' />
                                                    <p style={{ margin: 0 }} className={styles.connectedServices}>0</p>
                                                </span>
                                                <p style={{ margin: 0, fontWeight: 500, textAlign: "start" }}>Roteador<br />Mesh</p>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </article>
                        <article className={styles.aside}>
                            <Aside Info={data?.dadosAtividade} Image={hgu} />
                        </article>
                    </section>
                    {cards &&
                        <Carrousel carousel={cards} id={value} />
                    }

                </>
            }
        </div>
    )
}

export default Internet